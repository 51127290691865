import './App.css';
import { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs } from 'firebase/firestore';

function App() {
  const [users, setUsers] = useState([]);
  const userCollectionRef = collection(db, 'users');

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(userCollectionRef);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, [])
  return (
    <div>
      {users.map((user) => {
        return <div><h1>First Name: {user.firstName}</h1>
        <h1>Last Name: {user.lastName}</h1></div>
      })}
    </div>
  );
}

export default App;

import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBQWjS_xW8Am5Gi3m9aObxpljMotpIwuJU",
    authDomain: "test-fb-12e49.firebaseapp.com",
    projectId: "test-fb-12e49",
    storageBucket: "test-fb-12e49.appspot.com",
    messagingSenderId: "190928510177",
    appId: "1:190928510177:web:c5e99595ed9b5776b06ba0",
    measurementId: "G-7CGLT20JH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);